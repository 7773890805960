<template>
  <LoadingComponent v-if="loading" :width="true" />
  <div v-else class="day-summary-vt">
    <table class="grid" :style="computedStyle">
      <colgroup>
        <col width="18px" />
        <col width="50px" />
        <!-- <col width="60px" />
        <col width="68px" /> -->
        <col width="28px" />
        <col width="25px" />
        <col width="25px" />
        <!-- <col width="33px" /> -->
        <!-- 
        <col width="50px" />
        <col width="50px" />
        <col width="80px" />
        <col width="50px" />
        <col width="50px" />
        <col width="100px" />
        <col width="60px" />
        <col width="50px" />

        <col width="33px" />

        <col width="25px" />
        <col width="25px" />
        <col width="25px" />
        <col width="70px" /> -->
      </colgroup>
      <tr>
        <div class="color-title" :style="computedStyle">
          {{ technician.name }}
        </div>
      </tr>
      <tr>
        <th>H</th>
        <th>Lead</th>
        <!-- <th>Intervenants</th> -->
        <!-- <th>département</th> -->
        <th title="Code postal">CP</th>
        <th>Trajet</th>
        <th>RDV</th>
        <!-- <th class="grey-header" title="Total d'heures">T(h)</th> -->

        <!-- <th class="blue-header" title="Chauffage">Chauffage</th>
        <th class="blue-header" title="Ballon">Ballon</th>
        <th class="blue-header" title="Ballon Tampon">Ballon Tampon</th>
        <th class="blue-header" title="Isolation">Isolation</th>
        <th class="blue-header" title="Ambiance">Ambiance</th>
        <th class="blue-header" title="Chauffage Appoint">Chauffage Appoint</th>
        <th class="blue-header" title="Kit Bi-zone">Kit Bi-zone</th>
        <th class="blue-header" title="VMC">VMC</th> -->

        <!-- <th class="grey-header" title="Total des surfaces">T(m²)</th> -->
        <!-- <th class="icon-wrapper w13" title="Camion">
          <font-awesome-icon class="icon" :icon="['fas', 'truck']" />
        </th> -->
        <!-- <th class="icon-wrapper w14" title="Dossier">
          <font-awesome-icon class="icon" :icon="['fas', 'folder-open']" />
        </th>
        <th class="icon-wrapper w15" title="Photo">
          <font-awesome-icon class="icon" :icon="['fab', 'instagram']" />
        </th>
        <th class="icon-wrapper w15 pt-1" title="Confirmé">
          <WorkerIcon :height="'15px'" :width="'15px'" :fill="'#5e5e5e'" />
        </th>
        <th>Stade</th> -->
      </tr>
      <draggable
        style="width: 100%;
    display: contents;"
        @change="dragDropPlanningAMO($event, myList)"
        v-model="myList"
        group="people"
        @start="drag = true"
        @end="drag = false"
        handle=".handle"
        @clone="removechoose($event)"
        :disabled="getProjectsProcessing"
        @unchoose="endRemove($event)"
      >
        <tr
          v-for="(row, index) in computedDay.dayRows"
          :key="row.id + technician.name + index"
          :class="{
            off: !row.active,
            disabled: row.leads && row.leads.disabled === 1,
            simulatable: row.simulatable
          }"
        >
          <template>
            <td
              v-if="row.colorBlue"
              class="light-grey-column-hours"
              :class="{
                'color-rdv': row.colorBlue,
                handle: row.leads && row.leads.project_id
              }"
              @click="openModalInjection(row)"
            >
              <b-tooltip
                :target="'tooltip-target-' + row.leads.project_id"
                triggers="hover"
                v-if="row.leads && row.leads.project_id"
              >
                {{ popoverData(row.leads) }}
              </b-tooltip>
              <span
                :id="'tooltip-target-' + row.leads.project_id"
                :class="{
                  visibility: (row.simulatable && simulated) || row.loading
                }"
              >
                {{ row.heure ? row.heure : '' }}
              </span>
              <div
                v-if="row.loading"
                class="init-loading three-dots-loading color"
              ></div>
              <div
                v-if="row.simulatable && simulated"
                class="action"
                @click="resetSimulation"
              >
                <font-awesome-icon class="icon" icon="minus-square" />
              </div>
            </td>
            <td
              v-else
              class="light-grey-column-hours"
              :class="{
                'color-rdv': row.leads && row.leads.project_id,
                handle: row.leads && row.leads.project_id
              }"
              @click="
                row.leads && row.leads.project_id
                  ? openUpdateInjection(row)
                  : false
              "
            >
              <b-tooltip
                :target="'tooltip-target-' + row.leads.project_id"
                triggers="hover"
                v-if="row.leads && row.leads.project_id"
              >
                {{ popoverData(row.leads) }}
              </b-tooltip>
              <span
                :id="'tooltip-target-' + row.leads.project_id"
                :class="{
                  visibility: (row.simulatable && simulated) || row.loading
                }"
              >
                {{ row.heure ? row.heure : '' }}</span
              >
              <div
                v-if="row.loading"
                class="init-loading three-dots-loading color"
              ></div>
              <div
                v-if="row.simulatable && simulated"
                class="action"
                @click="resetSimulation"
              >
                <font-awesome-icon class="icon" icon="minus-square" />
              </div>
            </td>
            <td
              class="commentable"
              :class="{ 'grey-column': computedRegie == true }"
            >
              <!-- <TooltipComment
                v-if="row.leads && row.leads.comments && computedRegie == false"
                placement="right-start"
                :lead="row.leads"
                :canEdit="canEdit"
              > -->
              <TooltipLink
                v-if="row.leads && row.leads.project_id"
                :name="
                  row.leads.title_contact +
                    ' (' +
                    computedValueDepartement(row.leads.zipCode) +
                    ')'
                "
                :dealId="row.leads.project_id"
                :lead="row.leads"
                @changeSav="refreshCPlanning"
              >
                <div class="title-container">
                  <ColorTag
                    :color="
                      row.leads.sav === 1 ? 'blue' : colorStadeIte[row.index]
                    "
                    :style="{
                      left: '2px',
                      bottom: '2px',
                      top: '2px',
                      width: '10px',
                      'border-radius': '2px'
                    }"
                  />
                  <span
                    v-if="row.leads.comments && row.leads.comments.count"
                    class="comment-tag"
                  ></span>
                  <span>{{ row.leads.title_contact }} </span>
                  <p v-if="row.leads && row.leads.zipCode">
                    ({{ computedValueDepartement(row.leads.zipCode) }})
                  </p>
                </div>
              </TooltipLink>
              <!-- </TooltipComment> -->
            </td>
            <!-- <td
          class="text-left pl-1 pr-1"
          :title="row.lead ? row.lead.title_agent : ''"
        >
          <TooltipEditIntervenants
            v-if="row.lead"
            :canEdit="canEdit && !row.lead.disabled"
            :lead="row.lead"
          >
          </TooltipEditIntervenants>
          {{
            row.lead && row.lead.title_agent
              ? row.lead.title_agent.split(' ')[0]
              : ''
          }}
        </td>
        <td>
          {{ row.lead ? computedValueDepartement(row.lead.zipCode) : '' }}
        </td> -->
            <td>
              {{ row.leads ? row.leads.zipCode : '' }}
              <!-- <input
                v-if="row.simulatable"
                :id="technician.id"
                type="number"
                :value="simulated ? simulation[row.index].zipCode : null"
                @change="handleInputChange($event, 'zipCode', row.index)"
              /> -->
            </td>
            <td>
              {{
                row.simulatable && simulated
                  ? simulation[row.index].trajet_time_ite
                  : row.leads
                  ? row.leads.trajet_time_ite
                  : null | time
              }}
            </td>
            <td>
              {{
                row.simulatable && simulated
                  ? simulation[row.index].pose_time_ite
                  : row.leads
                  ? row.leads.pose_time_ite
                  : null | time
              }}
            </td>
          </template>
          <!-- <td>
          {{
            row.simulatable && simulated
              ? simulation[row.index].trajet_pose_ite_h_sum
              : row.lead
              ? row.lead.trajet_pose_ite_h_sum
              : null | time
          }}
        </td> -->

          <!-- <td
          :class="{
            colorable: row.lead && row.lead.surface_souffle > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead && computedRegie == false"
            field="surface_souffle"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="month_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_souffle && row.lead.surface_souffle_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_souffle +
                    ', Posé: ' +
                    row.lead.surface_souffle_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_souffle > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_souffle_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_souffle }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable && computedRegie == false"
            type="number"
            :value="simulated ? simulation[row.index].surface_souffle : null"
            @change="handleInputChange($event, 'surface_souffle', row.index)"
          />
          <template v-if="computedRegie">
            {{
              simulated ? simulation[row.index].surface_souffle : null
            }}</template
          >
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_deroule > 0,
            editable: canEdit
          }"
        >
          {{ row.lead ? row.lead.surface_deroule : '' }}
          <TooltipEdit
            v-if="row.lead && computedRegie == false"
            field="surface_deroule"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="month_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_deroule && row.lead.surface_deroule_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_deroule +
                    ', Posé: ' +
                    row.lead.surface_deroule_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_deroule > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_deroule_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_deroule }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable && computedRegie == false"
            type="number"
            :value="simulated ? simulation[row.index].surface_deroule : null"
            @change="handleInputChange($event, 'surface_deroule', row.index)"
          />
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_rampant > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead && computedRegie == false"
            field="surface_rampant"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="month_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_rampant && row.lead.surface_rampant_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_rampant +
                    ', Posé: ' +
                    row.lead.surface_rampant_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_rampant > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_rampant_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_rampant }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable && computedRegie == false"
            type="number"
            :value="simulated ? simulation[row.index].surface_rampant : null"
            @change="handleInputChange($event, 'surface_rampant', row.index)"
          />
          <template v-if="computedRegie">
            {{
              simulated ? simulation[row.index].surface_rampant : null
            }}</template
          >
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_mur > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead && computedRegie == false"
            field="surface_mur"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="month_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_mur && row.lead.surface_mur_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_mur +
                    ', Posé: ' +
                    row.lead.surface_mur_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_mur > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div class="info-surface" v-if="row.lead.surface_mur_pose > 0">
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_mur }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable && computedRegie == false"
            type="number"
            :value="simulated ? simulation[row.index].surface_mur : null"
            @change="handleInputChange($event, 'surface_mur', row.index)"
          />
          <template v-if="computedRegie">
            {{ simulated ? simulation[row.index].surface_mur : null }}</template
          >
        </td>
        <td
          :class="{
            colorable: row.lead && row.lead.surface_plafond > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead && computedRegie == false"
            field="surface_plafond"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="month_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_plafond && row.lead.surface_plafond_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_plafond +
                    ', Posé: ' +
                    row.lead.surface_plafond_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_plafond > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_plafond_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_plafond }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable && computedRegie == false"
            type="number"
            :value="simulated ? simulation[row.index].surface_plafond : null"
            @change="handleInputChange($event, 'surface_plafond', row.index)"
          />
          <template v-if="computedRegie">
            {{
              simulated ? simulation[row.index].surface_plafond : null
            }}</template
          >
        </td> -->
          <!-- <td
          :class="{
            colorable: row.lead && row.lead.surface_pignon > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead && computedRegie == false"
            field="surface_pignon"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="month_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_pignon && row.lead.surface_pignon_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_pignon +
                    ', Posé: ' +
                    row.lead.surface_pignon_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_pignon > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div class="info-surface" v-if="row.lead.surface_pignon_pose > 0">
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_pignon }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable && computedRegie == false"
            type="number"
            :value="simulated ? simulation[row.index].surface_pignon : null"
            @change="handleInputChange($event, 'surface_pignon', row.index)"
          />
          <template v-if="computedRegie">
            {{
              simulated ? simulation[row.index].surface_plafond : null
            }}</template
          >
        </td>

        <td
          :class="{
            colorable: row.lead && row.lead.surface_vide_sanitaire > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead && computedRegie == false"
            field="surface_vide_sanitaire"
            :canEdit="canEdit && !row.lead.disabled"
            :weekId="month_id"
            :lead="row.lead"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_vide_sanitaire &&
                row.lead.surface_vide_sanitaire_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_vide_sanitaire +
                    ', Posé: ' +
                    row.lead.surface_vide_sanitaire_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_vide_sanitaire > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div
                class="info-surface"
                v-if="row.lead.surface_vide_sanitaire_pose > 0"
              >
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_vide_sanitaire }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable && computedRegie == false"
            type="number"
            :value="
              simulated ? simulation[row.index].surface_vide_sanitaire : null
            "
            @change="
              handleInputChange($event, 'surface_vide_sanitaire', row.index)
            "
          />
          <template v-if="computedRegie">
            {{
              simulated ? simulation[row.index].surface_vide_sanitaire : null
            }}</template
          >
        </td>

        <td
          :class="{
            colorable: row.lead && row.lead.surface_iti > 0,
            editable: canEdit
          }"
        >
          <TooltipEdit
            v-if="row.lead && computedRegie == false"
            field="surface_iti"
            :weekId="month_id"
            :lead="row.lead"
            :canEdit="canEdit && !row.lead.disabled"
            :day="computedDay"
            :technician="technician"
          >
            <div
              class="edit"
              v-tooltip.top-center="
                row.lead.surface_iti && row.lead.surface_iti_pose > 0
                  ? 'Planifié: ' +
                    row.lead.surface_iti +
                    ', Posé: ' +
                    row.lead.surface_iti_pose
                  : null
              "
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />
            </div>
            <div class="content">
              <ColorTag
                v-if="row.lead.surface_iti > 0"
                :limit-color="true"
                :color="row.lead.sav === 1 ? 'blue' : colorStadeIte[row.index]"
                :style="{ top: 0, left: 0, bottom: 0, right: 0 }"
              />
              <div class="info-surface" v-if="row.lead.surface_iti_pose > 0">
                <font-awesome-icon class="icon" icon="info-circle" />
              </div>
              <span class="value">{{ row.lead.surface_iti }}</span>
            </div>
          </TooltipEdit>
          <input
            v-if="row.simulatable && computedRegie == false"
            type="number"
            :value="simulated ? simulation[row.index].surface_iti : null"
            @change="handleInputChange($event, 'surface_iti', row.index)"
          />
          <template v-if="computedRegie">
            {{ simulated ? simulation[row.index].surface_iti : null }}</template
          >
        </td>
        <td>
          {{ row.lead ? row.lead.total : '' }}
        </td>
        <td>
          <b-form-checkbox
            v-if="row.lead"
            class="planning-checkbox"
            :disabled="!canEdit || row.lead.disabled === 1"
            :value="1"
            :unchecked-value="0"
            v-model="dossier[row.id]"
            @change="handleCheckboxChange('dossier', row)"
          />
        </td>

        <td>
          <b-form-checkbox
            v-if="row.lead"
            class="planning-checkbox"
            :disabled="!canEdit || row.lead.disabled === 1"
            :value="1"
            :unchecked-value="0"
            v-model="photo[row.id]"
            @change="handleCheckboxChange('photo', row)"
          />
        </td>
        <td>
          <b-form-checkbox
            v-if="row.lead"
            class="planning-checkbox"
            :disabled="!canEdit || row.lead.disabled === 1"
            :value="1"
            :unchecked-value="0"
            v-model="confirmation_previsite[row.id]"
            @change="handleCheckboxChange('confirmation_previsite', row)"
          />
        </td>
        <td class="editable">
          <TooltipPipeLine
            v-if="row.lead"
            :lead="row.lead"
            placement="auto"
            :canEdit="canEdit && !row.lead.disabled"
            @changeStage="refreshCPlanning"
          >
            <Stage v-if="row.lead" :stage_id="row.lead.cstage_id" />
          </TooltipPipeLine>
          <b-form-checkbox
            v-else-if="canEdit && !computedDay.optimised"
            switch
            :checked="row.active"
            @change="handleSwitchChange(row)"
          ></b-form-checkbox>
        </td> -->
        </tr>
        <!-- <tr>
        <th rowspan="2" class="off"></th>
        <th><template v-if="computedRegie == false">CA</template></th>
        <th colspan="3">
          <template v-if="computedRegie == false">Planifié/Posé</template>
        </th>
        <th>Trajet</th>
        <th>RDV</th>
        <th>T(h)</th>
        <td
          class="light-grey-column "
          :class="{ 'fw-700': computedRegie == true }"
        >
          <template v-if="computedRegie == false">
            {{
              simulated
                ? simulation.total_s_planifie
                : computedDay.total_s_planifie
            }}
          </template>
          <template v-else>ECS</template>
        </td>
        <td
          class="light-grey-column "
          :class="{ 'fw-700': computedRegie == true }"
        >
          <template v-if="computedRegie == false">{{
            simulated
              ? simulation.total_d_planifie
              : computedDay.total_d_planifie
          }}</template>
          <template v-else>R/O</template>
        </td>
        <td
          class="light-grey-column "
          :class="{ 'fw-700': computedRegie == true }"
        >
          <template v-if="computedRegie === false">
            {{
              simulated
                ? simulation.total_r_planifie
                : computedDay.total_r_planifie
            }}</template
          >
          <template v-else>R/R</template>
        </td>
        <td
          class="light-grey-column "
          :class="{ 'fw-700': computedRegie == true }"
        >
          <template v-if="computedRegie == false">
            {{
              simulated
                ? simulation.total_m_planifie
                : computedDay.total_m_planifie
            }}</template
          >
          <template v-else>VMC</template>
        </td>
        <td
          class="light-grey-column "
          :class="{ 'fw-700': computedRegie == true }"
        >
          <template v-if="computedRegie == false">
            {{
              simulated
                ? simulation.total_p_planifie
                : computedDay.total_p_planifie
            }}</template
          >
          <template v-else>S</template>
        </td>
        <td
          class="light-grey-column"
          :class="{ 'fw-700': computedRegie == true }"
        >
          <template v-if="computedRegie == false">
            {{
              simulated
                ? simulation.total_pignon_planifie
                : computedDay.total_pignon_planifie
            }}
          </template>
          <template v-else>D</template>
        </td>
        <td
          class="light-grey-column"
          :class="{ 'fw-700': computedRegie == true }"
        >
          <template v-if="computedRegie == false">
            {{
              simulated
                ? simulation.total_vs_planifie
                : computedDay.total_vs_planifie
            }}</template
          >
          <template v-else>P</template>
        </td>
        <td
          class="light-grey-column"
          :class="{ 'fw-700': computedRegie == true }"
        >
          <template v-if="computedRegie == false">
            {{ simulated ? simulation.iti_day : computedDay.iti_day }}</template
          >
          <template v-else>R+</template>
        </td>
        <td :class="{ 'fw-700': computedRegie == true }">
          <template v-if="computedRegie == false">{{
            simulated
              ? simulation.total_total_m_planifie
              : computedDay.total_total_m_planifie
          }}</template>

          <template v-else>T(m²)</template>
        </td>
        <th colspan="3">
          <template v-if="computedRegie == false">Planifié</template>
        </th>
        <th
          rowspan="2"
          class="action"
          :class="{ optimised: computedDay.optimised }"
          @click="toggleOptimised"
        >
          {{ computedDay.optimised ? 'Optimisé' : 'Non optimisé' }}
        </th>
      </tr> -->
        <!-- <tr>
        <td :class="{ 'grey-column': computedRegie == true }">
          <template v-if="computedRegie == false">
            {{ computedDay.ca_d }}</template
          >
        </td>
        <td colspan="3" :class="{ 'grey-column': computedRegie == true }">
          <template v-if="computedRegie == false">{{
            computedDay.pose_planifie_d
              ? computedDay.pose_planifie_d.toFixed(2) + '%'
              : ''
          }}</template>
        </td>
        <td>
          <span>
            {{
              simulated
                ? simulation.total_trajet
                : computedDay.total_trajet | time
            }}
          </span>
        </td>
        <td>
          <span>
            {{
              simulated ? simulation.total_pose : computedDay.total_pose | time
            }}
          </span>
        </td>
        <td>
          <span>
            {{
              simulated
                ? simulation.total_total_h
                : computedDay.total_total_h | time
            }}
          </span>
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_s_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_d_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_r_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_m_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_p_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_pignon_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_vs_pose }}
        </td>
        <td class="light-grey-column">
          {{ computedDay.total_iti_pose }}
        </td>
        <td>
          {{ computedDay.total_total_m_pose }}
        </td>
        <th colspan="3">
          <template v-if="computedRegie == false">Posé</template>
        </th>
      </tr> -->
      </draggable>
    </table>

    <b-modal
      id="modal-center"
      ref="modal-center"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="content-injection">
        <div class="margin-div">
          Vous voulez confirmer l'injection de ce rendez vous dans la liste
        </div>
        <span class="gras">{{ technician.name }}</span> ayant pour date
        <span class="gras">{{ filialeAgence.date }}</span> et
        <div class="flex margin">
          <div class="flex">
            <div>heure de début</div>
            <VueCtkDateTimePicker
              id="hour-debut"
              v-model="timeDebut"
              :no-label="true"
              format="HH:mm"
              formatted="LT"
              label="HH:mm"
              locale="fr"
              :only-time="true"
              :disabled-hours="hoursDisable"
              :no-button="false"
              :auto-close="false"
              @input="changerHours"
              :disabled="warning && warning.length ? true : false"
            />
          </div>
          <div class="flex">
            <div>heure de fin</div>
            <VueCtkDateTimePicker
              id="hour-fin"
              v-model="timeFin"
              :no-label="true"
              format="HH:mm"
              locale="fr"
              formatted="LT"
              label="HH:mm"
              :only-time="true"
              :disabled-hours="[
                '00',
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '21',
                '22',
                '23'
              ]"
              :no-button="false"
              :auto-close="false"
              :disabled="warning && warning.length ? true : false"
            />
            .
          </div>
        </div>
      </div>
      <div class="message">
        <div v-if="getProjectsProcessing" class="loading m-2 pt-3">
          Chargement en cours...
        </div>
        <div v-if="error" class="error">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ error }}</span>
        </div>
        <div v-if="warning" class="warning">
          <ul v-if="Array.isArray(warning)" class="mr-3">
            <li v-for="(e, index) in warning" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ warning }}</span>
        </div>
        <div v-if="getProjectsError" class="error pt-3">
          <ul v-if="Array.isArray(getProjectsError)">
            <li v-for="(e, index) in getProjectsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getProjectsError }}</span>
        </div>
      </div>
      <div class="action">
        <b-button
          size="sm"
          v-if="warning && warning.length"
          variant="outline-secondary"
          @click="cancelModalWarning"
        >
          Fermer
        </b-button>
        <b-button
          size="sm"
          v-else
          variant="outline-secondary"
          @click="cancelModal"
        >
          Annuler
        </b-button>
        <b-button
          v-if="row && row.colorBlue && (!warning || !warning.length)"
          type="submit"
          size="sm"
          variant="success"
          class="ml-2"
          @click="injecterRDVParFileProjet"
        >
          Injecter RDV AMO
        </b-button>
        <b-button
          v-if="row && !row.colorBlue && (!warning || !warning.length)"
          type="submit"
          size="sm"
          variant="success"
          class="ml-2"
          @click="InjecterRdvAMO"
        >
          Injecter RDV AMO
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-confirm-rdv"
      ref="modal-confirm-rdv"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div>
        Un rendez vous est injecté déjà à cette heure , merci de choisir une
        autre.
      </div>
      <b-button
        class="postion-btn"
        size="sm"
        variant="outline-secondary"
        @click="cancelModalConfirm"
      >
        Fermer
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingComponent from '@/components/ui/LoadingComponent'
import * as moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import draggable from 'vuedraggable'
// import TooltipInjecterRdvAmo from '@/views/PlanningAMO/TooltipInjecterRdvAmo.vue'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
momentDurationFormatSetup(moment)
moment.locale('fr')
export default {
  name: 'PlanningVisiteurTechDaySummary',
  components: {
    draggable,
    LoadingComponent,
    // TooltipInjecterRdvAmo,
    VueCtkDateTimePicker,
    // TooltipPipeLine: () => import('@/components/ui/TooltipPipeLine'),
    // TooltipComment: () => import('@/components/ui/TooltipComment'),
    TooltipLink: () => import('@/components/ui/TooltipLink'),
    // TooltipEdit: () => import('@/components/ui/TooltipEdit'),
    ColorTag: () => import('@/components/ui/ColorTag')
    // Stage: () => import('@/components/Stage'),
    // TooltipEditIntervenants: () =>
    // import('@/components/ui/TooltipEditIntervenants'),
    // WorkerIcon: () => import('@/components/ui/WorkerIcon.vue')
  },
  props: {
    day: {
      required: true
    },
    technician: {
      required: true
    },
    filialeAgence: {
      required: true
    },
    month_id: {
      required: true
    },
    depot_zipCode: {
      required: false
    },
    user_zipCode: {
      required: false
    },
    canEdit: {
      default: false
    },
    loading: {
      default: false
    },
    planning: { required: false }
  },
  data() {
    return {
      confirmation: {
        icon: ['fas', 'user-tie'],
        title: 'Confirmé'
      },
      dossier: [],
      photo: [],
      confirmation_previsite: [],
      simulation: {
        1: {
          zipCode: null,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        2: {
          zipCode: null,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        3: {
          zipCode: null,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        4: {
          zipCode: null,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        5: {
          zipCode: null,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        6: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        7: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        8: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        9: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        10: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        11: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        12: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        13: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        14: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        total_trajet: 0,
        total_pose: 0,
        total_total_h: 0
      },
      simulated: false,
      copyLoading: false,
      timeDebut: null,
      timeFin: null,
      hoursDisable: [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23'
      ],
      bodyRdv: null,
      movedRdv: null,
      removed: null,
      row: null,
      dataInjecterRDV: null,
      error: [],
      warning: []
    }
  },
  methods: {
    ...mapActions([
      'updateFieldOptimiseCPlanning',
      'changeCEEPhotoMeetingCommercial',
      'updateRowStatusCPlanning',
      'getSimulatedTrajetCPlanning',
      'fetchCPlanning',
      'getContactPhone',
      'updateProject',
      'fetchObjectDayRow',
      'resetErrorProject'
    ]),
    changerHours() {
      if (this.timeDebut) {
        let hour = this.timeDebut.split(':')[0]
        let minute = this.timeDebut.split(':')[1]
        let sommeHour = Number(hour)
        if (sommeHour == 20) {
          this.timeFin = `${sommeHour}:${minute}`
        } else {
          sommeHour = sommeHour + 1
          this.timeFin = `${sommeHour}:${minute}`
        }
      }
    },
    validateInjection(field) {
      const validate = {
        status: 'success',
        errors: [],
        show: true
      }
      if (field && field.length) {
        for (let index = 0; index < field.length; index++) {
          if (
            field[index].key == 'hour_visite_tech' &&
            field[index].value == null
          ) {
            validate.status = 'error'
            validate.errors.push('Le champ heure de début est obligatoire')
          }
          if (
            field[index].key == 'date_fin_visite_tech' &&
            field[index].value == null
          ) {
            validate.status = 'error'
            validate.errors.push('Le champ heure de fin est obligatoire')
          }
        }
      }
      return validate
    },
    async injecterRDVParFileProjet() {
      this.resetErrorProject()
      const payload = {}
      let dateFinPrevisite = `${moment(
        this.filialeAgence.date,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD')} ${moment(this.timeFin, 'HH:mm').format(
        'HH:mm:ss'
      )}`
      let datePrevisite = `${moment(
        this.filialeAgence.date,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD')} ${moment(this.timeDebut, 'HH:mm').format(
        'HH:mm:ss'
      )}`
      let field_group = [
        {
          key: 'date_visite_tech',
          value: this.timeDebut ? datePrevisite : null
        },
        {
          key: 'hour_visite_tech',
          value: this.timeDebut
            ? moment(this.timeDebut, 'HH:mm').format('HH:mm')
            : null
        },
        {
          key: 'date_fin_visite_tech',
          value: this.timeFin ? dateFinPrevisite : null
        },
        // FILIALE + AGENCE +AMO
        {
          key: 'visiteur_tech',
          value:
            this.technician && this.technician.id
              ? parseInt(this.technician.id)
              : null
        },
        {
          key: 'depot_visiteur_tech_id',
          value:
            this.filialeAgence && this.filialeAgence.agence
              ? parseInt(this.filialeAgence.agence)
              : null
        },
        {
          key: 'team_visiteur_tech_id',
          value:
            this.filialeAgence && this.filialeAgence.filiale
              ? parseInt(this.filialeAgence.filiale)
              : null
        }
      ]
      payload.entity = JSON.parse(localStorage.getItem('vt')).entity
      payload.field = field_group
      payload.value = field_group
      //  pour tester ce fields dans file projet js
      payload.field_group = true
      payload.planningAmo = true
      // update rdv de td
      const validate = this.validateInjection(payload.field)
      if (validate.status === 'error') {
        this.error = validate.errors
        return
      }
      await this.updateProject(payload)
      this.$nextTick(async () => {
        if (this.getResponseUpdateProjectPlanning) {
          this.fetchCPlanning({
            planning: 'planning-visiteur-tech',
            day_nbr: this.filialeAgence.day,
            comm_ids: [this.technician.id]
          })
          if (this.getProjectsWarning) {
            this.warning = this.getProjectsWarning
          }
          // AFFICAHGE DE PLANNING AMO PAR DATE CURRENT
          else {
            this.$refs['modal-center'].hide()
            localStorage.removeItem('vt')
          }
          if (this.day && this.day.dayRows && this.day.dayRows.length) {
            for (let index = 0; index < this.planning.length; index++) {
              if (
                this.planning[index].days &&
                this.planning[index].days.length
              ) {
                for (
                  let j = 0;
                  j < this.planning[index].days[0].dayRows.length;
                  j++
                ) {
                  this.planning[index].days[0].dayRows[j].colorBlue = false
                }
              }
            }
          }
        }
      })
      if (this.getResponseUpdateProjectPlanning) {
        this.resetErrorProject()
        this.deletedInjection()
      }
    },
    openUpdateInjection(row) {
      this.resetErrorProject()
      this.error = []
      this.warning = []
      this.row = row
      let dateTimeDebut =
        row && row.leads && row.leads.date_visite_tech
          ? row.leads.date_visite_tech.split(' ')[1]
          : null
      this.timeDebut = moment(dateTimeDebut, 'HH:mm:ss').format('HH:mm')
      let dateTime =
        row && row.leads && row.leads.date_fin_visite_tech
          ? row.leads.date_fin_visite_tech.split(' ')[1]
          : null
      this.timeFin = moment(dateTime, 'HH:mm:ss').format('HH:mm')
      // **********************************
      let formatHeure = moment(this.timeDebut, 'HH:mm:ss').format('HH')
      this.hoursDisable = this.hoursDisable.filter(item => {
        return item != formatHeure
      })
      this.$refs['modal-center'].show()
    },
    openModalInjection(row) {
      this.resetErrorProject()
      this.error = []
      this.warning = []
      this.row = row
      if (row && row.leads && row.leads.project_id) {
        this.$refs['modal-confirm-rdv'].show()
      } else {
        this.timeDebut = moment(row.heure.split('h')[0], 'HH').format('HH')
        if (this.timeDebut && JSON.parse(localStorage.getItem('vt'))) {
          let hour = this.timeDebut.split(':')[0]
          let minute = this.timeDebut.split(':')[1]
          let sommeHour = Number(hour)
          if (sommeHour == 20) {
            this.timeFin = `${sommeHour}:59`
          } else {
            sommeHour = sommeHour + 1
            this.timeFin = `${sommeHour}:${minute}`
          }
        }
        // **********************************
        this.hoursDisable = this.hoursDisable.filter(item => {
          return item != this.timeDebut
        })
        this.$refs['modal-center'].show()
      }
    },
    cancelModalConfirm() {
      this.$refs['modal-confirm-rdv'].hide()
      this.resetErrorProject()
      this.error = []
      this.warning = []
    },
    async cancelModalWarning() {
      this.$refs['modal-center'].hide()
      this.error = []
      this.warning = []
      this.resetErrorProject()
      this.timeDebut = null
      this.timeFin = null
      this.hoursDisable = [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23'
      ]
    },
    cancelModal() {
      if (this.movedRdv && this.movedRdv.moved) {
        let leads = null
        for (let index = 0; index < this.movedRdv.DayRow.length; index++) {
          if (
            this.movedRdv.DayRow[index].index == this.movedRdv.moved.newIndex &&
            this.movedRdv.DayRow[index].leads &&
            this.movedRdv.DayRow[index].leads.project_id
          ) {
            leads = this.movedRdv.DayRow[index].leads
            this.movedRdv.DayRow[index].leads = []
          }
        }
        if (leads) {
          this.movedRdv.DayRow[this.movedRdv.moved.oldIndex - 1].leads = leads
        }
      }
      if (
        this.movedRdv &&
        this.movedRdv.added &&
        this.movedRdv.DayRow &&
        this.movedRdv.DayRow.length
      ) {
        if (this.movedRdv.added.newIndex != 15) {
          this.movedRdv.DayRow[this.movedRdv.added.newIndex - 1].leads = []
        } else {
          this.movedRdv.DayRow[13].leads = []
        }
      }
      if (JSON.parse(localStorage.getItem('removed'))) {
        for (let index = 0; index < this.planning.length; index++) {
          if (
            this.planning[index].id ==
            JSON.parse(localStorage.getItem('removed')).amo
          ) {
            {
              if (
                this.planning[index].days &&
                this.planning[index].days.length
              ) {
                for (
                  let j = 0;
                  j < this.planning[index].days[0].dayRows.length;
                  j++
                ) {
                  if (
                    this.planning[index].days[0].dayRows[j].index ==
                    JSON.parse(localStorage.getItem('removed')).oldIndex
                  ) {
                    this.planning[index].days[0].dayRows[j].leads = JSON.parse(
                      localStorage.getItem('removed')
                    ).element.leads
                  }
                }
              }
            }
            break
          }
        }
      }
      this.error = []
      this.warning = []
      this.resetErrorProject()
      this.$refs['modal-center'].hide()
      this.timeDebut = null
      this.timeFin = null
      this.hoursDisable = [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23'
      ]
    },
    async InjecterRdvAMO() {
      this.resetErrorProject()
      this.error = []
      this.warning = []
      if (this.bodyRdv) {
        if (this.bodyRdv && this.bodyRdv.field && this.bodyRdv.field.length) {
          let date = this.dataInjecterRDV
            ? moment(this.dataInjecterRDV, 'YYYY-MM-DD HH:mm:ss').format(
                'YYYY-MM-DD'
              )
            : null
          let dateFinPrevisite = `${date} ${moment(
            this.timeFin,
            'HH:mm'
          ).format('HH:mm:ss')}`
          let datePrevisite = `${date} ${moment(this.timeDebut, 'HH:mm').format(
            'HH:mm:ss'
          )}`
          for (let index = 0; index < this.bodyRdv.field.length; index++) {
            if (this.bodyRdv.field[index].key == 'date_fin_visite_tech') {
              this.bodyRdv.field[index].value = this.timeFin
                ? dateFinPrevisite
                : null
            }
            if (this.bodyRdv.field[index].key == 'date_visite_tech') {
              this.bodyRdv.field[index].value = this.timeDebut
                ? datePrevisite
                : null
            }

            if (this.bodyRdv.field[index].key == 'hour_visite_tech') {
              this.bodyRdv.field[index].value = this.timeDebut
                ? this.timeDebut
                : null
            }
          }
        }
        const validate = this.validateInjection(this.bodyRdv.field)
        if (validate.status === 'error') {
          this.error = validate.errors
          return
        }
        if (this.row && this.row.leads) {
          this.bodyRdv.entity = this.row.leads.project_id
        }
        await this.updateProject(this.bodyRdv).then(async () => {
          if (this.getProjectResponseUpdate) {
            localStorage.removeItem('removed')
            // AFFICAHGE DE PLANNING AMO PAR DATE CURRENT
            this.fetchCPlanning({
              planning: 'planning-visiteur-tech',
              day_nbr: this.filialeAgence.day,
              comm_ids: [this.technician.id]
            })
            if (this.getProjectsWarning) {
              this.warning = this.getProjectsWarning
            } else {
              this.$refs['modal-center'].hide()
            }
          }
        })
      } else {
        const payload = {}
        let date =
          this.row && this.row.leads && this.row.leads.date_visite_tech
            ? moment(
                this.row.leads.date_visite_tech,
                'YYYY-MM-DD HH:mm:ss'
              ).format('YYYY-MM-DD')
            : null
        let dateFinPrevisite = `${date} ${moment(this.timeFin, 'HH:mm').format(
          'HH:mm:ss'
        )}`
        let datePrevisite = `${date} ${moment(this.timeDebut, 'HH:mm').format(
          'HH:mm:ss'
        )}`
        let field_group = [
          {
            key: 'date_visite_tech',
            value: this.timeDebut ? datePrevisite : null
          },
          {
            key: 'hour_visite_tech',
            value: this.timeDebut ? this.timeDebut : null
          },
          {
            key: 'date_fin_visite_tech',
            value: this.timeFin ? dateFinPrevisite : null
          },
          // FILIALE + AGENCE +AMO
          {
            key: 'visiteur_tech',
            value:
              this.technician && this.technician.id
                ? parseInt(this.technician.id)
                : null
          },
          {
            key: 'depot_visiteur_tech_id',
            value:
              this.filialeAgence && this.filialeAgence.agence
                ? parseInt(this.filialeAgence.agence)
                : null
          },
          {
            key: 'team_visiteur_tech_id',
            value:
              this.filialeAgence && this.filialeAgence.filiale
                ? parseInt(this.filialeAgence.filiale)
                : null
          }
        ]
        payload.entity = this.row.leads.project_id
        payload.field = field_group
        payload.value = field_group
        //  pour tester ce fields dans file projet js
        payload.field_group = true
        payload.planningAmo = true
        const validate = this.validateInjection(payload.field)
        if (validate.status === 'error') {
          this.error = validate.errors
          return
        }
        await this.updateProject(payload).then(async () => {
          if (this.getProjectResponseUpdate) {
            localStorage.removeItem('removed')
            // AFFICAHGE DE PLANNING AMO PAR DATE CURRENT
            this.fetchCPlanning({
              planning: 'planning-visiteur-tech',
              day_nbr: this.filialeAgence.day,
              comm_ids: [this.technician.id]
            })
            if (this.getProjectsWarning) {
              this.warning = this.getProjectsWarning
            } else {
              this.$refs['modal-center'].hide()
              localStorage.removeItem('vt')
            }
            if (this.day && this.day.dayRows && this.day.dayRows.length) {
              for (let index = 0; index < this.planning.length; index++) {
                if (
                  this.planning[index].days &&
                  this.planning[index].days.length
                ) {
                  for (
                    let j = 0;
                    j < this.planning[index].days[0].dayRows.length;
                    j++
                  ) {
                    this.planning[index].days[0].dayRows[j].colorBlue = false
                  }
                }
              }
            }
          }
        })
      }
    },
    deletedInjection() {
      localStorage.removeItem('vt')
    },
    endRemove(rdv) {
      rdv.item.style.color = '#656565'
      rdv.item.style.backgroundColor = 'transparent'
    },
    removechoose(rdv) {
      rdv.item.style.color = '#dbdbdb'
      rdv.item.style.backgroundColor = '#dbdbdb'
    },
    async dragDropPlanningAMO(e, DayRow) {
      this.resetErrorProject()
      const payload = {}
      // Moved TR IN ONE TABLE AMO
      if (e && e.moved) {
        this.movedRdv = {
          moved: e.moved,
          DayRow: DayRow
        }
        // INCREMENTER INDEX DRAG AND DROP POUR IL COMPATIBLE AVEC INDEX DE TABLE AMO
        e.moved.oldIndex += 1
        e.moved.newIndex += 1
        // ADDED LOADING IN TR OLDINDEX AND NEWINDEX
        // for (let index = 0; index < DayRow.length; index++) {
        //   const element = DayRow[index]
        //   element.loading = true
        // }
        // BOUCLE FOR POUR LA TABLE DE AMO
        for (let index = 0; index < DayRow.length; index++) {
          const element = DayRow[index]
          // CAS UPDATE RDV
          if (
            e.moved.element &&
            e.moved.element.leads &&
            e.moved.element.leads.project_id &&
            e.moved.newIndex == element.index &&
            element.leads &&
            !element.leads.project_id
          ) {
            let date =
              e.moved &&
              e.moved.element &&
              e.moved.element.leads.date_visite_tech
                ? moment(
                    e.moved.element.leads.date_visite_tech,
                    'YYYY-MM-DD HH:mm:ss'
                  ).format('YYYY-MM-DD')
                : null
            this.dataInjecterRDV = date
            let dateTime = `${date} ${
              element.heure && element.heure.split('h')[0]
                ? moment(element.heure.split('h')[0], 'HH').format('HH:mm:ss')
                : ''
            }`
            this.timeDebut =
              element.heure && element.heure.split('h')[0]
                ? moment(element.heure.split('h')[0], 'HH').format('HH:mm')
                : null
            if (this.timeDebut) {
              let timeDebutFormat = moment(
                this.timeDebut.split(':')[0],
                'HH'
              ).format('HH')
              this.hoursDisable = this.hoursDisable.filter(item => {
                return item != timeDebutFormat
              })
            }
            let field_group = [
              {
                key: 'date_visite_tech',
                value: dateTime ? dateTime : null
              },
              {
                key: 'hour_visite_tech',
                value: this.timeDebut ? this.timeDebut : null
              },
              {
                key: 'date_fin_visite_tech',
                value: null
              },
              // FILIALE + AGENCE +AMO
              {
                key: 'visiteur_tech',
                value:
                  this.technician && this.technician.id
                    ? parseInt(this.technician.id)
                    : null
              },
              {
                key: 'depot_visiteur_tech_id',
                value:
                  this.filialeAgence && this.filialeAgence.agence
                    ? parseInt(this.filialeAgence.agence)
                    : null
              },
              {
                key: 'team_visiteur_tech_id',
                value:
                  this.filialeAgence && this.filialeAgence.filiale
                    ? parseInt(this.filialeAgence.filiale)
                    : null
              }
            ]
            payload.entity = e.moved.element.leads.project_id
            payload.field = field_group
            payload.value = field_group
            //  pour tester ce fields dans file projet js
            payload.field_group = true
            payload.planningAmo = true
            // update rdv de td
            this.bodyRdv = payload
            // await this.updateProject(payload)
            // if (this.getProjectResponseUpdate) {
            element.leads = e.moved.element.leads
            element.simulatable = e.moved.element.simulatable
            // }
            //  CAS UPDATE RDV
            // if (this.getProjectResponseUpdate) {
            for (let index = 0; index < DayRow.length; index++) {
              const element = DayRow[index]
              if (
                e.moved.element &&
                e.moved.element.leads &&
                e.moved.oldIndex == element.index
              ) {
                element.leads = []
                break
              }
            }
            // *****************************
            if (this.timeDebut) {
              let hour = this.timeDebut.split(':')[0]
              let minute = this.timeDebut.split(':')[1]
              let sommeHour = Number(hour)
              if (sommeHour == 20) {
                this.timeFin = `${sommeHour}:59`
              } else {
                sommeHour = sommeHour + 1
                this.timeFin = `${sommeHour}:${minute}`
              }
            }
            this.$refs['modal-center'].show()
            this.row = DayRow
            // }
            break
          }
        }
        //  REFRESH ORDER TABLE
        this.$nextTick(() => {
          //  CAS UPDATE RDV
          // if (this.getProjectResponseUpdate) {
          // for (let index = 0; index < DayRow.length; index++) {
          //   const element = DayRow[index]
          //   if (
          //     e.moved.element &&
          //     e.moved.element.leads &&
          //     e.moved.oldIndex == element.index
          //   ) {
          //     element.leads = []
          //     break
          //   }
          // }
          // }
          DayRow.sort(function compare(a, b) {
            if (a.index < b.index) return -1
            if (a.index > b.index) return 1
            return 0
          })
          // ADDED LOADING IN TR OLDINDEX AND NEWINDEX
          // for (let index = 0; index < DayRow.length; index++) {
          //   const element = DayRow[index]
          //   element.loading = false
          // }
        })
      }
      // MOVED TR ENTRE TWO TABLE
      // *************************************************************
      if (e && e.added) {
        e.added.newIndex += 1
        e.added.element.remove = true
        this.movedRdv = {
          added: e.added,
          DayRow: DayRow
        }
        // ADDED LOADING IN TR OLDINDEX AND NEWINDEX
        // if (
        //   e.added &&
        //   e.added.element &&
        //   e.added.element.leads &&
        //   e.added.element.leads.project_id
        // ) {
        //   for (let index = 0; index < DayRow.length; index++) {
        //     const element = DayRow[index]
        //     element.loading = true
        //   }
        //   setTimeout(() => {
        //     for (let index = 0; index < DayRow.length; index++) {
        //       const element = DayRow[index]
        //       element.loading = false
        //     }
        //   }, 500)
        // }
        // cas add lead vide
        if (
          e.added &&
          e.added.element &&
          e.added.element.leads &&
          Array.isArray(e.added.element.leads) &&
          e.added.element.leads.length == 0
        ) {
          localStorage.removeItem('movedRdv')
          var myIndex = DayRow.map(item => {
            if (
              item.index == e.added.element.index &&
              item.leads &&
              item.leads.length == 0
            ) {
              return item.remove
            }
          }).indexOf(true)
          if (myIndex !== -1) {
            DayRow.splice(myIndex, 1)
          }
        }
        // cas add lead not vide
        else {
          for (let index = 0; index < DayRow.length; index++) {
            const element = DayRow[index]
            // add lead dans tr vide
            if (
              element.index == e.added.newIndex &&
              element.leads &&
              element.leads.length == 0
            ) {
              localStorage.setItem('movedRdv', 0)
              this.$nextTick(() => {
                // update objet leads
                let datezz = moment(
                  e.added.element.leads.date_visite_tech,
                  'YYYY-MM-DD HH:mm:ss'
                ).format('YYYY-MM-DD')
                this.dataInjecterRDV = datezz
                let heure = moment(element.heure.split('h')[0], 'HH').format(
                  'HH:mm:ss'
                )
                this.timeDebut =
                  element.heure && element.heure.split('h')[0]
                    ? moment(element.heure.split('h')[0], 'HH').format('HH:mm')
                    : null
                if (this.timeDebut) {
                  let timeDebutFormat = moment(
                    this.timeDebut.split(':')[0],
                    'HH'
                  ).format('HH')
                  this.hoursDisable = this.hoursDisable.filter(item => {
                    return item != timeDebutFormat
                  })
                }
                e.added.element.leads.date_visite_tech = `${datezz} ${heure}`
                e.added.element.leads.hour_visite_tech = element.heure
                e.added.element.heure = element.heure
                e.added.element.index = e.added.newIndex
                e.added.element.id = `row_index_${e.added.newIndex}_on`
                let field_group = [
                  {
                    key: 'date_visite_tech',
                    value: e.added.element.leads.date_visite_tech
                      ? e.added.element.leads.date_visite_tech
                      : null
                  },
                  {
                    key: 'hour_visite_tech',
                    value:
                      element.heure && element.heure.split('h')[0]
                        ? moment(element.heure.split('h')[0], 'HH').format(
                            'HH:mm'
                          )
                        : null
                  },
                  {
                    key: 'date_fin_visite_tech',
                    value: null
                  },
                  // FILIALE + AGENCE +AMO
                  {
                    key: 'visiteur_tech',
                    value:
                      this.technician && this.technician.id
                        ? parseInt(this.technician.id)
                        : null
                  },
                  {
                    key: 'depot_visiteur_tech_id',
                    value:
                      this.filialeAgence && this.filialeAgence.agence
                        ? parseInt(this.filialeAgence.agence)
                        : null
                  },
                  {
                    key: 'team_visiteur_tech_id',
                    value:
                      this.filialeAgence && this.filialeAgence.filiale
                        ? parseInt(this.filialeAgence.filiale)
                        : null
                  }
                ]
                payload.entity = e.added.element.leads.project_id
                payload.field = field_group
                payload.value = field_group
                //  pour tester ce fields dans file projet js
                payload.field_group = true
                payload.planningAmo = true
                // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                var myIndexAdded = DayRow.map(item => {
                  if (
                    item.index == e.added.newIndex &&
                    item.heure == e.added.element.heure &&
                    e.added.newIndex == e.added.element.index
                  ) {
                    return item.remove
                  }
                }).indexOf(false)
                if (myIndexAdded !== -1) {
                  DayRow.splice(myIndexAdded, 1)
                }
                this.bodyRdv = payload
                if (payload.field && payload.entity) {
                  // *****************************
                  if (this.timeDebut) {
                    let hour = this.timeDebut.split(':')[0]
                    let minute = this.timeDebut.split(':')[1]
                    let sommeHour = Number(hour)
                    if (sommeHour == 20) {
                      this.timeFin = `${sommeHour}:59`
                    } else {
                      sommeHour = sommeHour + 1
                      this.timeFin = `${sommeHour}:${minute}`
                    }
                  }
                  this.$refs['modal-center'].show()
                  this.row = DayRow
                  // await this.updateProject(payload)
                  // if (this.getProjectResponseUpdate) {
                  // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                  var deletedRdvOne = DayRow.map(item => {
                    if (
                      item.index == e.added.newIndex &&
                      item.leads &&
                      item.leads.length == 0
                    ) {
                      return item.index
                    }
                  }).indexOf(e.added.element.index)
                  if (deletedRdvOne !== -1) {
                    DayRow.splice(deletedRdvOne, 1)
                  }
                }
                // }
              })
              break
            }
            // add lead dans tr not vide
            if (
              element.index == e.added.newIndex &&
              element.leads &&
              element.leads.project_id
            ) {
              localStorage.removeItem('movedRdv')
              let table = []
              for (let index = 0; index < DayRow.length; index++) {
                const element = DayRow[index]
                if (
                  element.index == e.added.newIndex &&
                  element.heure == e.added.element.heure &&
                  e.added.newIndex == e.added.element.index &&
                  element.leads &&
                  element.leads.project_id
                ) {
                  table.push(element)
                }
              }
              // add rd de meme heure et les deux remplir
              if (table && table.length == 2) {
                // ADDED LOADING IN TR OLDINDEX AND NEWINDEX
                for (let index = 0; index < DayRow.length; index++) {
                  const element = DayRow[index]
                  element.loading = false
                }
                localStorage.setItem('movedRdv', 1)
                var myIndexAddedDeleted = DayRow.map(item => {
                  if (
                    item.index == e.added.newIndex &&
                    item.heure == e.added.element.heure &&
                    e.added.newIndex == e.added.element.index
                  ) {
                    return item.remove
                  }
                }).indexOf(true)
                if (myIndexAddedDeleted !== -1) {
                  DayRow.splice(myIndexAddedDeleted, 1)
                }
              }
              //
              else if (table && table.length == 1) {
                localStorage.setItem('movedRdv', 0)
                this.$nextTick(() => {
                  let datezz = moment(
                    e.added.element.leads.date_visite_tech,
                    'YYYY-MM-DD HH:mm:ss'
                  ).format('YYYY-MM-DD')
                  this.dataInjecterRDV = datezz
                  this.timeDebut =
                    element.leads.date_visite_tech &&
                    element.leads.date_visite_tech.split(' ')[1]
                      ? moment(
                          element.leads.date_visite_tech.split(' ')[1],
                          'HH:mm:ss'
                        ).format('HH:mm')
                      : null

                  if (this.timeDebut) {
                    let timeDebutFormat = moment(
                      this.timeDebut.split(':')[0],
                      'HH'
                    ).format('HH')
                    this.hoursDisable = this.hoursDisable.filter(item => {
                      return item != timeDebutFormat
                    })
                  }
                  // objet update rdv
                  let field_group = [
                    {
                      key: 'date_visite_tech',
                      value: element.leads.date_visite_tech
                        ? element.leads.date_visite_tech
                        : null
                    },
                    {
                      key: 'hour_visite_tech',
                      value:
                        element.leads.date_visite_tech &&
                        element.leads.date_visite_tech.split(' ')[1]
                          ? moment(
                              element.leads.date_visite_tech.split(' ')[1],
                              'HH:mm:ss'
                            ).format('HH:mm')
                          : null
                    },
                    {
                      key: 'date_fin_visite_tech',
                      value: null
                    },
                    // FILIALE + AGENCE +AMO
                    {
                      key: 'visiteur_tech',
                      value:
                        this.technician && this.technician.id
                          ? parseInt(this.technician.id)
                          : null
                    },
                    {
                      key: 'depot_visiteur_tech_id',
                      value:
                        this.filialeAgence && this.filialeAgence.agence
                          ? parseInt(this.filialeAgence.agence)
                          : null
                    },
                    {
                      key: 'team_visiteur_tech_id',
                      value:
                        this.filialeAgence && this.filialeAgence.filiale
                          ? parseInt(this.filialeAgence.filiale)
                          : null
                    }
                  ]

                  payload.entity = e.added.element.leads.project_id
                  payload.field = field_group
                  payload.value = field_group
                  //  pour tester ce fields dans file projet js
                  payload.field_group = true
                  payload.planningAmo = true
                  // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                  var myIndexAddeddeleted = DayRow.map(item => {
                    if (
                      item.index == e.added.newIndex &&
                      item.heure == e.added.element.heure &&
                      e.added.newIndex == e.added.element.index
                    ) {
                      return item.remove
                    }
                  }).indexOf(false)
                  if (myIndexAddeddeleted !== -1) {
                    DayRow.splice(myIndexAddeddeleted, 1)
                  }
                  this.bodyRdv = payload
                  if (payload.field && payload.entity) {
                    // *****************************
                    if (this.timeDebut) {
                      let hour = this.timeDebut.split(':')[0]
                      let minute = this.timeDebut.split(':')[1]
                      let sommeHour = Number(hour)
                      if (sommeHour == 20) {
                        this.timeFin = `${sommeHour}:59`
                      } else {
                        sommeHour = sommeHour + 1
                        this.timeFin = `${sommeHour}:${minute}`
                      }
                    }
                    this.$refs['modal-center'].show()
                    this.row = DayRow
                    // await this.updateProject(payload)
                    // if (this.getProjectResponseUpdate) {
                    // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                    var deletedRdv = DayRow.map(item => {
                      if (
                        item.index == e.added.newIndex &&
                        item.heure == e.added.element.heure &&
                        e.added.newIndex == e.added.element.index &&
                        item.leads &&
                        item.leads.length == 0
                      ) {
                        return item.index
                      }
                    }).indexOf(e.added.element.index)
                    if (deletedRdv !== -1) {
                      DayRow.splice(deletedRdv, 1)
                    }
                    // }
                  }
                })
              }
              // table vide tester par newindex and length leads
              else {
                // ADDED LOADING IN TR OLDINDEX AND NEWINDEX
                for (let index = 0; index < DayRow.length; index++) {
                  const element = DayRow[index]
                  element.loading = false
                }
                localStorage.setItem('movedRdv', 1)
                for (let index = 0; index < DayRow.length; index++) {
                  const element = DayRow[index]
                  if (
                    element.index == e.added.newIndex &&
                    element.leads &&
                    element.leads.project_id
                  ) {
                    var myIndexAddedDeletedFalse = DayRow.map(item => {
                      //
                      if (
                        item.index == e.added.element.index &&
                        item.heure == e.added.element.heure
                      ) {
                        return item.remove
                      }
                    }).indexOf(true)
                    if (myIndexAddedDeletedFalse !== -1) {
                      DayRow.splice(myIndexAddedDeletedFalse, 1)
                    }
                    DayRow.sort(function compare(a, b) {
                      if (a.index < b.index) return -1
                      if (a.index > b.index) return 1
                      return 0
                    })
                  }
                }
              }
              break
            }
            // CAS ADDED LEAD DANS LA DERNIER ELEMENT DE TABLE ET SUPERIEUR INDEX TABLE
            if (e.added.newIndex >= 15 && element.index == 14) {
              localStorage.removeItem('movedRdv')
              //  si lead d'index 14 est vide
              if (
                element.index == 14 &&
                element.leads &&
                element.leads.length == 0
              ) {
                localStorage.setItem('movedRdv', 0)
                // update objet leads
                this.$nextTick(() => {
                  let datezz = moment(
                    e.added.element.leads.date_visite_tech,
                    'YYYY-MM-DD HH:mm:ss'
                  ).format('YYYY-MM-DD')
                  this.dataInjecterRDV = datezz
                  let heure = '20:00:00'
                  this.timeDebut = '20:00'
                  this.hoursDisable = this.hoursDisable.filter(item => {
                    return item != '20'
                  })
                  e.added.element.leads.date_visite_tech = `${datezz} ${heure}`
                  e.added.element.leads.hour_visite_tech = '20h'
                  e.added.element.heure = '20h'
                  e.added.element.index = 14
                  e.added.element.id = `row_index_14_on`
                  let field_group = [
                    {
                      key: 'date_visite_tech',
                      value: e.added.element.leads.date_visite_tech
                        ? e.added.element.leads.date_visite_tech
                        : null
                    },
                    {
                      key: 'hour_visite_tech',
                      value: '20:00'
                    },
                    {
                      key: 'date_fin_visite_tech',
                      value: null
                    },
                    // FILIALE + AGENCE +AMO
                    {
                      key: 'visiteur_tech',
                      value:
                        this.technician && this.technician.id
                          ? parseInt(this.technician.id)
                          : null
                    },
                    {
                      key: 'depot_visiteur_tech_id',
                      value:
                        this.filialeAgence && this.filialeAgence.agence
                          ? parseInt(this.filialeAgence.agence)
                          : null
                    },
                    {
                      key: 'team_visiteur_tech_id',
                      value:
                        this.filialeAgence && this.filialeAgence.filiale
                          ? parseInt(this.filialeAgence.filiale)
                          : null
                    }
                  ]
                  //
                  payload.entity = e.added.element.leads.project_id
                  payload.field = field_group
                  payload.value = field_group
                  //  pour tester ce fields dans file projet js
                  payload.field_group = true
                  payload.planningAmo = true
                  // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                  var myIndex2 = DayRow.map(item => {
                    if (item.index == 14) {
                      return item.remove
                    }
                  }).indexOf(false)
                  if (myIndex2 !== -1) {
                    DayRow.splice(myIndex2, 1)
                  }
                  this.bodyRdv = payload
                  if (payload.field && payload.entity) {
                    // *****************************
                    if (this.timeDebut) {
                      let hour = this.timeDebut.split(':')[0]
                      let minute = this.timeDebut.split(':')[1]
                      let sommeHour = Number(hour)
                      if (sommeHour == 20) {
                        this.timeFin = `${sommeHour}:59`
                      } else {
                        sommeHour = sommeHour + 1
                        this.timeFin = `${sommeHour}:${minute}`
                      }
                    }
                    this.$refs['modal-center'].show()
                    this.row = DayRow
                    // await this.updateProject(payload)
                    // if (this.getProjectResponseUpdate) {
                    // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                    var deletedRdvSupIndex = DayRow.map(item => {
                      if (
                        item.index == e.added.element.index &&
                        item.heure == e.added.element.heure &&
                        item.leads &&
                        item.leads.length == 0
                      ) {
                        return item.index
                      }
                    }).indexOf(e.added.element.index)
                    if (deletedRdvSupIndex !== -1) {
                      DayRow.splice(deletedRdvSupIndex, 1)
                    }
                    // }
                  }
                })
              }
              //  si lead d'index 14 not vide
              else {
                // ADDED LOADING IN TR OLDINDEX AND NEWINDEX
                for (let index = 0; index < DayRow.length; index++) {
                  const element = DayRow[index]
                  element.loading = false
                }
                localStorage.setItem('movedRdv', 1)
                // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                if (e.added.element.index != 14) {
                  var myIndexAddeddeletedRdv = DayRow.map(item => {
                    if (item.index == e.added.element.index) {
                      return item.remove
                    }
                  }).indexOf(true)
                  if (myIndexAddeddeletedRdv !== -1) {
                    DayRow.splice(myIndexAddeddeletedRdv, 1)
                  }
                } else {
                  // REMOVED DUPLICATION INDEX DANS UN SEUL TABLE
                  var myIndex3 = DayRow.map(item => {
                    if (
                      item.index == 14 &&
                      element.leads.project_id != item.leads.project_id
                    ) {
                      return item.index
                    }
                  }).indexOf(14)
                  if (myIndex3 !== -1) {
                    DayRow.splice(myIndex3, 1)
                  }
                }
              }
              break
            }
          }
        }
      }
      // *************************************************************
      if (e && e.removed) {
        e.removed.oldIndex += 1
        e.removed.amo = this.technician.id
        localStorage.setItem('removed', JSON.stringify(e.removed))
        // ADDED LOADING IN TR OLDINDEX AND NEWINDEX
        for (let index = 0; index < DayRow.length; index++) {
          const element = DayRow[index]
          element.loading = false
        }
        // cas add lead vide
        if (
          e.removed &&
          e.removed.element &&
          e.removed.element.leads &&
          Array.isArray(e.removed.element.leads) &&
          e.removed.element.leads.length == 0
        ) {
          // ADDED LOADING IN TR OLDINDEX AND NEWINDEX
          for (let index = 0; index < DayRow.length; index++) {
            const element = DayRow[index]
            element.loading = false
          }
          localStorage.removeItem('movedRdv')
          DayRow.push(e.removed.element)
          DayRow.sort(function compare(a, b) {
            if (a.index < b.index) return -1
            if (a.index > b.index) return 1
            return 0
          })
        }
        // cas add lead not vide
        else {
          // CAS DEPLACER RDV DANS AUTRE TABLE
          if (
            parseInt(localStorage.getItem('movedRdv')) == 0 &&
            e.removed.element.leads &&
            e.removed.element.leads.project_id
          ) {
            // ADDED LOADING IN TR OLDINDEX AND NEWINDEX
            for (let index = 0; index < DayRow.length; index++) {
              const element = DayRow[index]
              element.loading = false
            }
            for (let index = 0; index < DayRow.length; index++) {
              const element = DayRow[index]
              // create new tr vide
              if (element.index != e.removed.oldIndex) {
                let leadObject = {
                  active: e.removed.element.active,
                  heure: e.removed.element.heure,
                  id: e.removed.element.id,
                  index: e.removed.element.index,
                  leads: [],
                  _lead: e.removed.element._lead,
                  comm_ite: parseInt(this.technician.id)
                }
                this.fetchObjectDayRow(leadObject)
                this.$nextTick(async () => {
                  let dayRow = this.getObjectDayRow[0]
                  DayRow.push(dayRow)
                  DayRow.sort(function compare(a, b) {
                    if (a.index < b.index) return -1
                    if (a.index > b.index) return 1
                    return 0
                  })
                })
                break
              }
            }
          } else if (
            parseInt(localStorage.getItem('movedRdv')) == 1 &&
            e.removed.element.leads &&
            e.removed.element.leads.project_id
          ) {
            // ADDED LOADING IN TR OLDINDEX AND NEWINDEX
            for (let index = 0; index < DayRow.length; index++) {
              const element = DayRow[index]
              element.loading = false
            }
            DayRow.push(e.removed.element)
            DayRow.sort(function compare(a, b) {
              if (a.index < b.index) return -1
              if (a.index > b.index) return 1
              return 0
            })
          }
        }
      }
    },
    hover(e) {
      if (e.target.classList[2] == 'fa-user-tie') {
        this.confirmation.icon = ['fas', 'copy']
        this.confirmation.title = 'Copier'
      }
      if (e.target.classList[2] == 'fa-copy') {
        this.confirmation.icon = ['fas', 'user-tie']
        this.confirmation.title = 'Confirmé'
      }
    },
    async copyToClipboard(day) {
      try {
        if (this.copyLoading) {
          return
        }
        this.copyLoading = true
        let texts = []
        if (day.leads && day.leads.length) {
          for (let index = 0; index < day.leads.length; index++) {
            const lead = day.leads[index]

            const date = moment(lead.date_visite_tech).format('dddd D MMMM')
            const startHoure = lead.hour_visite_tech
            const endHoure = moment(startHoure, 'HH[h]')
              .add(lead.trajet_pose_ite_h_sum, 'minutes')
              .format('HH[h]mm')

            let phones = await this.getContactPhone(lead.contact_id)
            const phone = phones.join(' / ')

            const commercial = lead.title_comm_ite
              ? lead.title_comm_ite
              : lead.title_comm_terrain

            let surfaces = ''
            if (lead.surface_souffle > 0) {
              surfaces += 'Soufflé: ' + lead.surface_souffle + 'm²\n'
            }
            if (lead.surface_deroule > 0) {
              surfaces += 'Déroulé: ' + lead.surface_deroule + 'm²\n'
            }
            if (lead.surface_rampant > 0) {
              surfaces += 'Rampant: ' + lead.surface_rampant + 'm²\n'
            }
            if (lead.surface_mur > 0) {
              surfaces += 'Mur: ' + lead.surface_mur + 'm²\n'
            }
            if (lead.surface_plafond > 0) {
              surfaces += 'Plafond: ' + lead.surface_plafond + 'm²\n'
            }
            if (lead.surface_pignon > 0) {
              surfaces += 'Pignon: ' + lead.surface_pignon + 'm²\n'
            }
            if (lead.surface_vide_sanitaire > 0) {
              surfaces +=
                'Vide sanitaire: ' + lead.surface_vide_sanitaire + 'm²\n'
            }
            if (lead.surface_ite > 0) {
              surfaces += 'ITE: ' + lead.surface_ite + 'm²\n'
            }
            if (lead.surface_iti > 0) {
              surfaces += 'ITI+: ' + lead.surface_iti + 'm²\n'
            }

            texts.push(
              `Prévisite: ${date} entre ${startHoure}-${endHoure}
Previsteur/Commercial: ${commercial}
Client: ${lead.title_contact}
${lead.prj_adresse}
${lead.zipCode} ${lead.prj_ville}
${lead.precarite ? lead.precarite + ' - ' : ''}${lead.type_heating}
${surfaces}Numéro de téléphone: ${phone}
Informations complémentaire:`
            )
            if (index >= 0 && index < day.leads.length - 1) {
              texts.push('\n***********************************************\n')
            }
          }
        }
        const text = texts.join('')

        const el = document.createElement('textarea')
        el.value = text
        el.setAttribute('readonly', '')
        el.style.position = 'absolute'
        el.style.left = '-9999px'
        document.body.appendChild(el)
        el.select()
        el.setSelectionRange(0, 99999) /*For mobile devices*/
        document.execCommand('copy')
        document.body.removeChild(el)

        this.$bvToast.toast(text, {
          title: 'Texte copié',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          solid: true
        })
        this.copyLoading = false
      } catch (error) {
        this.copyLoading = false
      }
    },
    toggleOptimised() {
      if (
        this.currentUser.isSuper ||
        (this.canEdit &&
          (this.currentUser.type === 'user.admin' ||
            this.currentUser.type === 'user.resp.plan')) ||
        (this.currentUser.id = 687)
      ) {
        this.updateFieldOptimiseCPlanning({
          day: this.computedDay
        })
      }
    },
    handleCheckboxChange(field, row) {
      if (field) {
        this.$nextTick(() => {
          this.changeCEEPhotoMeetingCommercial({
            lead: row.lead,
            field: field,
            value: row.lead[field] == 1 ? 0 : 1
          })
        })
      }
    },
    handleSwitchChange(row) {
      this.updateRowStatusCPlanning({
        row: row,
        day: this.computedDay
      })
    },
    async refreshCPlanning() {
      await this.fetchCPlanning('planning-visiteur-tech')
    },
    handleInputChange(e, field, index) {
      this.simulation[index][field] = e.target.value
      this.$nextTick(async () => {
        this.simulated = true

        // Simulated trajet
        if (field == 'zipCode') {
          let origin = this.depot_zipCode
          if (this.user_zipCode) {
            origin = this.user_zipCode
          }
          if (
            index > 1 &&
            this.computedDay.dayRows[index - 2].lead &&
            this.computedDay.dayRows[index - 2].lead.zipCode
          ) {
            origin = this.computedDay.dayRows[index - 2].lead.zipCode
          }
          this.simulation[index][
            'trajet_time_ite'
          ] = await this.getSimulatedTrajetCPlanning({
            origin: origin,
            destination: this.simulation[index].zipCode
          })
        }

        // Total simulated trajet
        if (this.simulation[index]['trajet_time_ite']) {
          this.simulation[index]['trajet_pose_ite_h_sum'] += this.simulation[
            index
          ]['trajet_time_ite']
          this.simulation['total_trajet'] =
            this.computedDay.total_trajet +
            this.simulation[index]['trajet_time_ite']
        }

        // Total simulated RDV
        this.simulation[index]['pose_time_ite'] = 60
        this.simulation[index]['trajet_pose_ite_h_sum'] += this.simulation[
          index
        ]['pose_time_ite']
        this.simulation['total_pose'] =
          this.computedDay.total_pose + this.simulation[index]['pose_time_ite']

        // Total simulated Total hours
        this.simulation['total_total_h'] =
          this.computedDay.total_total_h +
          this.simulation[index]['trajet_pose_ite_h_sum']
      })
    },
    resetSimulation() {
      this.simulation = {
        1: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        2: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        3: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        4: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        5: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        6: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        7: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        8: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        9: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        10: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        11: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        12: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        13: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        14: {
          zipCode: 0,
          trajet_time_ite: 0,
          pose_time_ite: 0,
          trajet_pose_ite_h_sum: 0
        },
        total_trajet: 0,
        total_pose: 0,
        total_total_h: 0
      }
      this.simulated = false
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getPlanningPipeline',
      'getProjectsWarning',
      'getProjectResponseUpdate',
      'getObjectDayRow',
      'getProjectsError',
      'getProjectsProcessing',
      'getResponseUpdateProjectPlanning'
    ]),
    computedStyle() {
      if (window.innerWidth <= 500) {
        return {
          width: window.innerWidth - 50 + 'px'
        }
      }
      return {}
    },
    popoverData() {
      return function(data) {
        if (data) {
          let timedebutFormat = data.date_visite_tech
            ? data.date_visite_tech.split(' ')[1]
            : ''
          let heurDebut = timedebutFormat
            ? moment(timedebutFormat, 'HH:mm:ss').format('HH[h]mm')
            : ''
          let timeFinFormat = data.date_fin_visite_tech
            ? data.date_fin_visite_tech.split(' ')[1]
            : ''
          let heureFin = timeFinFormat
            ? '  -  ' + moment(timeFinFormat, 'HH:mm:ss').format('HH[h]mm')
            : ''

          return heurDebut + heureFin
        }
        if (JSON.parse(localStorage.getItem('vt'))) {
          let timedebutFormat = JSON.parse(localStorage.getItem('vt')).date_rdv
            ? JSON.parse(localStorage.getItem('vt')).date_rdv.split(' ')[1]
            : ''
          let heurDebut = timedebutFormat
            ? moment(timedebutFormat, 'HH:mm:ss').format('HH[h]mm')
            : ''
          let timeFinFormat = JSON.parse(localStorage.getItem('vt'))
            .date_fin_visite_tech
            ? JSON.parse(localStorage.getItem('vt')).date_fin_visite_tech.split(
                ' '
              )[1]
            : ''
          let heureFin = timeFinFormat
            ? '  -  ' + moment(timeFinFormat, 'HH:mm:ss').format('HH[h]mm')
            : ''

          return heurDebut + heureFin
        }
      }
    },
    myList: {
      get() {
        return this.computedDay.dayRows
      },
      set(value) {
        return (this.computedDay.dayRows = value)
      }
    },
    colorStadeIte: function() {
      let status = []
      for (let index = 0; index < this.computedDay.dayRows.length; index++) {
        const element = this.computedDay.dayRows[index]
        if (
          element.leads &&
          element.leads.cpipeline_id &&
          element.leads.cstage_id &&
          element.leads.csubstage_id
        ) {
          this.getPlanningPipeline.forEach(pipeline => {
            if (pipeline.id == element.leads.cpipeline_id) {
              pipeline.stages.forEach(stages => {
                if (stages.id == element.leads.cstage_id) {
                  stages.csubstages.data.filter(substage => {
                    if (substage.id == element.leads.csubstage_id) {
                      status[element.index] = substage.color
                    }
                  })
                }
              })
            }
          })
        }
      }
      return status
    },
    computedDay: function() {
      if (this.day && this.day.dayRows && this.day.dayRows.length) {
        this.day.dayRows.forEach(row => {
          if (row.lead) {
            this.dossier[row.id] = row.lead.dossier
            this.photo[row.id] = row.lead.photo
            this.confirmation_previsite[row.id] =
              row.lead.confirmation_previsite
          }
        })
        return this.day
      }
      return {}
    },
    computedRegie() {
      return (
        this.currentUser &&
        (this.currentUser.type == 'user.commercial' ||
          this.currentUser.type == 'user.final' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            (this.currentUser.details.team.data.type === 'regie' ||
              this.currentUser.details.team.data.type === 'installateur')))
      )
    },
    computedValueDepartement() {
      return function(data) {
        if (data) {
          return data.substring(0, 2)
        }
      }
    }
  },
  filters: {
    time: function(value) {
      if (value === null || value === undefined) return ''
      if (!value) return '00h00'
      if (!Number.isInteger(value)) {
        value = parseInt(value)
      }
      if (value < 10) return '00h0' + value
      if (value < 60) return '00h' + value
      value = moment.duration(value, 'minutes').format('HH[h]mm')
      return value
    },
    colorStatus: function(value) {
      switch (value) {
        case 'red':
          return 'redStatus'
        case 'orange':
          return 'orangeStatus'
        case 'yellow':
          return 'yellow'
        case 'green':
          return 'green'
      }
      return ''
    },
    date: function(value) {
      if (!value) return ''
      return moment(value, 'DD-MM-YYYY').format('DD/MM/YYYY')
    }
  },
  watch: {
    computedDay() {
      if (
        JSON.parse(localStorage.getItem('vt')) &&
        (JSON.parse(localStorage.getItem('vt')).amo_id == null ||
          JSON.parse(localStorage.getItem('vt')).date_rdv == null)
      ) {
        if (this.day && this.day.dayRows && this.day.dayRows.length) {
          for (let index = 0; index < this.day.dayRows.length; index++) {
            this.day.dayRows[index].colorBlue = true
          }
        }
      }
    }
  },
  mounted() {
    localStorage.removeItem('movedRdv')
    if (
      JSON.parse(localStorage.getItem('vt')) &&
      (JSON.parse(localStorage.getItem('vt')).amo_id == null ||
        JSON.parse(localStorage.getItem('vt')).date_rdv == null)
    ) {
      if (this.day && this.day.dayRows && this.day.dayRows.length) {
        for (let index = 0; index < this.day.dayRows.length; index++) {
          this.day.dayRows[index].colorBlue = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.day-summary-vt {
  .handle {
    float: left;
    // padding-top: 8px;
    // padding-bottom: 8px;
  }
  table {
    &.grid {
      width: 220px;
      border-collapse: collapse;
      table-layout: fixed;
      box-sizing: border-box;
      empty-cells: show;
      outline: 0;

      tr {
        height: 25px;

        th,
        td {
          position: relative;
          text-align: center;
          border: 1px solid #e8e8e8;
          outline: 0;
          vertical-align: middle;
          overflow: hidden;
          font-weight: 500;
          text-overflow: ellipsis;
          font-size: 9px;
          white-space: nowrap;
          box-sizing: border-box;
          padding: 0 1px;

          &.off {
            background-color: #9c9c9c;
            border-color: #8e8e8e;
          }
        }

        th {
          font-weight: 700;
          background-color: #ededed;
          &.blue-header {
            background-color: #2dabe2;
            color: #fff;
          }
          &.grey-header {
            background-color: #6c757d;
            color: #fff;
          }

          &.icon-wrapper {
            padding: 0 2px;
            &.w13 {
              font-size: 13px;
            }
            &.w14 {
              font-size: 14px;
            }
            &.w15 {
              font-size: 15px;
              &.copy {
                cursor: pointer;
              }
            }
            .icon {
              &.rotating {
                animation: rotating 1s linear infinite;
              }
            }
          }

          &.action {
            position: relative;
            padding: 0 2px;
            cursor: pointer;
            &.optimised {
              background-color: #2dabe2;
              color: #fff;
            }
          }
        }
        td {
          &.light-grey-column {
            background-color: #dbdbdb;
            .visibility {
              visibility: hidden;
            }
          }
          &.light-grey-column-hours {
            background-color: #dbdbdb;
            border: 1px solid #dbdbdb;
            padding: 3px;
            width: 100%;
            width: 103%;
            margin-left: -1px;
            font-size: 10px;
            display: block;
            // cursor: move;
            .visibility {
              visibility: hidden;
            }
            .color {
              color: #404346;
              font-weight: bold;
              background-color: #dbdbdb;
            }
            &.color-rdv {
              &:hover {
                background-color: #5ca9fc;
                border: 1px solid #5ca9fc;
                color: white;
                padding: 3px;
                width: 100%;
                font-size: 10px;
                /* font-weight: bold; */
                display: block;
                cursor: pointer;
              }
            }
          }
          .planning-checkbox {
            padding-left: 27px;
          }
        }

        &.off {
          th,
          td {
            background-color: #9c9c9c;
            border-color: #9c9c9c;
          }
        }
        &.disabled {
          th,
          td {
            background-color: #dbdbdb;
            border-color: #dbdbdb;
          }
        }

        &.simulatable {
          td {
            position: relative;
            input {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              border: none;
              text-align: center;
            }

            .action {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              color: #e73e01;
            }
          }
        }

        &:hover {
          color: #656565;
          background-color: #ededed;
        }
      }
    }
  }
  @keyframes rotating {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
}
</style>

<style lang="scss">
.day-summary-vt {
  table {
    &.grid {
      td {
        .v-popover {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          .trigger {
            display: block !important;
            position: relative;
            width: 100%;
            height: 100%;
          }
        }

        &.editable {
          position: relative;
          .edit {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            font-size: 12px;
            justify-content: center;
            align-items: center;
            background: #dbdbdb;
            color: #2dabe2;
            z-index: 3;
            cursor: pointer;
          }

          .content {
            height: 100%;
            padding: 2px 2px 0;
            .info-surface {
              position: absolute;
              top: -7px;
              right: 1px;
              font-size: 7px;
              color: #2dabe2;
              z-index: 1;
            }
          }
          &:hover {
            .edit {
              display: flex;
            }
          }
        }

        &.commentable {
          position: relative;
          .v-popover {
            .trigger {
              .title-container {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                padding-left: 15px;
                // .comment-tag {
                //   position: absolute;
                //   width: 20px;
                //   height: 10px;
                //   background: #ffe436;
                //   z-index: 9;
                //   display: block;
                //   top: -2px;
                //   right: -7px;
                //   transform: rotateZ(45deg);
                // }
                span {
                  outline: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                p {
                  margin-top: 0;
                  margin-bottom: 0;
                  margin-left: 2px;
                }
              }
            }
          }
        }

        &.colorable {
          .value {
            position: relative;
            z-index: 2;
          }
        }
      }
    }
    .color {
      font-weight: 700;
      background-color: #ededed;
    }
    .color-title {
      border: 1px solid #e8e8e8;
      width: 221px;
      width: 220px;
      margin-left: -1px;
      background-color: #ededed;
      padding: 3px;
      text-align: center;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2dabe2;
    background-color: #2dabe2;
  }
}
#modal-confirm-rdv {
  .postion-btn {
    margin-top: 7px;
    float: right;
  }
}
#modal-center {
  .content-injection {
    .gras {
      font-weight: bold;
    }
    .date-time-picker {
      width: 50%;
      height: 21px;
      margin: -5px 7px;
      text-align: left;
      font-size: 15px;
      border-radius: 4px;
      position: relative;
    }
    .field-input {
      height: 30px;
      min-height: 30px;
    }
    .flex {
      display: flex;
      // align-items: center;
    }
    .margin {
      margin: 11px 0px;
    }
    .margin-div {
      margin-bottom: 7px;
    }
  }
  .action {
    text-align: end;
    margin-top: 18px;
    margin-bottom: -7px;
  }
  .warning {
    color: #f39b0e;
  }
}
</style>
