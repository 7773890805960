var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content",class:{
    'mise-en-page-bureau': _vm.estEcranBureau,
    'mise-en-page-mobile': !_vm.estEcranBureau
  }},[(_vm.estEcranBureauHeader)?_c('div',{staticClass:"header"},[_c('div',{staticClass:"logo",class:{ default: !_vm.computedPlanning.filial_img },style:({
        'background-image': _vm.computedPlanning.filial_img
          ? ("url(" + (_vm.computedPlanning.filial_img) + ")")
          : 'none'
      })}),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.hundleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"filters"},[_c('div',{staticClass:"deposit-number"},[_vm._v(" "+_vm._s(_vm.computedPlanning.depot_nbr ? _vm.computedPlanning.depot_nbr : '- -')+" ")])]),_c('div',{staticClass:"filters"},[_c('b-form-group',{staticClass:"planning-filtres",attrs:{"label":"Filiale","label-for":"filiale-input"}},[_c('b-form-select',{attrs:{"id":"filiale-input","options":_vm.getAvailableFiliales,"value-field":"id","text-field":"name","required":""},on:{"change":_vm.hundleFilialeChange},scopedSlots:_vm._u([(!_vm.getAvailableFiliales || !_vm.getAvailableFiliales.length)?{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- Aucune filiale disponible --")])]},proxy:true}:null],null,true),model:{value:(_vm.selectedFiliale),callback:function ($$v) {_vm.selectedFiliale=$$v},expression:"selectedFiliale"}})],1),_c('b-form-group',{staticClass:"planning-filtres",attrs:{"label":"Agence","label-for":"deposit-input"}},[_c('b-form-select',{attrs:{"id":"deposit-input","options":_vm.getAvailableDeposits,"value-field":"id","text-field":"name","required":""},on:{"change":_vm.hundleChangeFilterDeposite},scopedSlots:_vm._u([(!_vm.getAvailableDeposits || !_vm.getAvailableDeposits.length)?{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- Aucune agence disponible --")])]},proxy:true}:null],null,true),model:{value:(_vm.selectedDeposit),callback:function ($$v) {_vm.selectedDeposit=$$v},expression:"selectedDeposit"}})],1)],1),_c('div',{staticClass:"filters"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-primary","disabled":_vm.getCPlanningProcessing,"title":"Actualiser planning"},on:{"click":_vm.handleRefresh}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"sync-alt"}})],1),(_vm.getCPlanningProcessing)?_c('b-spinner',{style:({
            opacity: _vm.getCPlanningProcessing ? 1 : 0
          }),attrs:{"label":"Loading..."}}):_vm._e()],1),_c('div',{staticClass:"filters"},[(_vm.computedErrors)?_c('div',{staticClass:"error"},[(Array.isArray(_vm.computedErrors))?_c('ul',_vm._l((_vm.computedErrors),function(error,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_c('span',[_vm._v("computedErrors")])]):_vm._e()])])]):_c('div',{staticClass:"header"},[_c('div',{staticClass:"logo",class:{ default: !_vm.computedPlanning.filial_img },style:({
        'background-image': _vm.computedPlanning.filial_img
          ? ("url(" + (_vm.computedPlanning.filial_img) + ")")
          : 'none'
      })}),_c('div',{staticClass:"show-filiale-agence"},[_c('div',{staticClass:"filters"},[_c('div',{staticClass:"deposit-number"},[_vm._v(" "+_vm._s(_vm.computedPlanning.depot_nbr ? _vm.computedPlanning.depot_nbr : '- -')+" ")])])]),_c('div',{staticClass:"grp-btn"},[_c('div',{staticClass:"filter-btn",on:{"click":_vm.showBtnFilters}},[_c('font-awesome-icon',{attrs:{"icon":"filter"}})],1),_c('div',{staticClass:"filters"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-primary","disabled":_vm.getCPlanningProcessing,"title":"Actualiser planning"},on:{"click":_vm.handleRefresh}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"sync-alt"}})],1)],1)]),_c('div',{staticClass:"filters"},[(_vm.computedErrors)?_c('div',{staticClass:"error"},[(Array.isArray(_vm.computedErrors))?_c('ul',_vm._l((_vm.computedErrors),function(error,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_c('span',[_vm._v("computedErrors")])]):_vm._e()])]),_c('div',{staticClass:"content",class:{ minimised: _vm.minimised, 'day-stat-on': _vm.minimisedDayStat }},[_c('div',{staticClass:"days",class:{
        'mise-en-page-bureau': _vm.estEcranBureau,
        'mise-en-page-mobile': !_vm.estEcranBureau
      }},[_c('div',{staticClass:"day week"},[_vm._v(" Visiteur Technique "),_c('div',{staticClass:"icon-wrapper",on:{"click":_vm.openMinimised}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['far', 'caret-square-left']}})],1)]),(_vm.selected && _vm.selected.length)?_c('div',{staticClass:"day capitalize",style:(_vm.computedStyle(_vm.computedPlanning.commercials))},[_vm._v(" "+_vm._s(_vm.computedPlanning.dayPlanning)+" ")]):_vm._e()]),_c('div',{staticClass:"content-wrapper",class:{
        'mise-en-page-bureau': _vm.estEcranBureau,
        'mise-en-page-mobile': !_vm.estEcranBureau
      }},[_c('div',{staticClass:"week-column"},[_c('div',{staticClass:"week-summary-vt"},[_c('div',{staticClass:"block-week",class:{ minimised: _vm.minimised }},[_c('div',{staticClass:"week-summary-vt-content"},[_c('div',{staticClass:"input-date-rdv"},[_c('b-form-input',{attrs:{"placeholder":"DD/MM/YYYY","disabled":true},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}}),_c('div',{staticClass:"icon-date",on:{"click":_vm.openDate}},[_c('font-awesome-icon',{attrs:{"icon":"calendar-day"}})],1)],1),(_vm.openDateInput)?_c('div',{staticClass:"filter-date-rdv mb-2"},[_c('div',[_c('VueCtkDateTimePicker',{attrs:{"format":"DD/MM/YYYY","formatted":"l","no-label":true,"only-date":true,"locale":"fr","no-header":true,"inline":true,"input-size":"lg","button-color":"green","no-button":true,"auto-close":true,"label":"DD/MM/YYYY","max-date":'2024/01/01',"min-date":'2019/01/01',"button-now-translation":"Maintenant"},on:{"input":function($event){return _vm.changeValueDate(_vm.selectedDate)}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)]):_vm._e(),(_vm.getCommercialsCPlanningProcessing)?_c('span',{staticClass:"init-loading three-dots-loading  ml-3 mb-3"},[_vm._v("Chargement en cours ")]):_vm._e(),(
                  _vm.computedCommercials &&
                    _vm.computedCommercials.length &&
                    !_vm.getCommercialsCPlanningProcessing
                )?[_c('div',{staticClass:"check-vt",class:{ margin: !_vm.openDateInput }},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"flex-item"},[_c('b-form-checkbox',{attrs:{"indeterminate":_vm.indeterminate,"disabled":_vm.injecterRdvAMO ? true : false},on:{"change":_vm.toggleAll},model:{value:(_vm.allSelected),callback:function ($$v) {_vm.allSelected=$$v},expression:"allSelected"}},[_c('p',{staticClass:"margin-check"},[_vm._v(" "+_vm._s(_vm.allSelected ? 'Sélectionnez tout' : _vm.indeterminate ? 'Sélectionnez tout' : 'Sélectionnez aucun')+" ")])]),_c('div',{staticClass:"btn-planning"},[(
                              _vm.filterCheckedAmo &&
                                _vm.filterCheckedAmo.name &&
                                _vm.filterCheckedAmo.name.length &&
                                !_vm.sortDesc
                            )?_c('b-button',{staticClass:"icon-filtre",on:{"click":_vm.sortBy}},[_c('font-awesome-icon',{staticClass:"filter-icon-sort",attrs:{"icon":['fas', 'caret-down']}})],1):_vm._e(),(
                              _vm.filterCheckedAmo &&
                                _vm.filterCheckedAmo.name &&
                                _vm.filterCheckedAmo.name.length &&
                                _vm.sortDesc
                            )?_c('b-button',{staticClass:"icon-filtre",on:{"click":_vm.sortBy}},[_c('font-awesome-icon',{staticClass:"filter-icon-sort",attrs:{"icon":['fas', 'caret-up']}})],1):_vm._e(),(
                              _vm.filterCheckedAmo &&
                                _vm.filterCheckedAmo.name &&
                                _vm.filterCheckedAmo.name.length
                            )?_c('b-button',{staticClass:"icon-filtre",attrs:{"title":"Voir de(s) visiteur technique(s) selectionné(s)"},on:{"click":_vm.openFiltreAmo}},[_c('font-awesome-icon',{staticClass:"filter-icon",attrs:{"icon":['fas', 'eye']}})],1):_vm._e()],1)],1)]},proxy:true}],null,false,4109819139)},[[_c('b-form-checkbox-group',{staticClass:"ml-4",attrs:{"stacked":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},_vm._l((_vm.computedCommercials),function(item,index){return _c('b-form-checkbox',{key:item.id,attrs:{"value":item,"disabled":_vm.injecterRdvAMO
                              ? _vm.checkDisabled(item, _vm.selected)
                              : false},on:{"change":function($event){return _vm.checkIntervenantVt(_vm.selected, index)}}},[_vm._v(" "+_vm._s(item.full_name)+" ")])}),1)]],2)],1)]:(
                  (!_vm.computedCommercials || !_vm.computedCommercials.length) &&
                    !_vm.getCommercialsCPlanningProcessing
                )?[_c('span',{staticClass:"msg-aucun ml-3"},[_vm._v("Aucun Liste des Visiteurs Techniques ")])]:_vm._e()],2),_c('div',{staticClass:"message"},[(_vm.loading)?_c('div',{staticClass:"three-dots-loading"},[_vm._v(" Chargement en cours ")]):_vm._e(),(_vm.error)?_c('div',{staticClass:"error-color"},[(Array.isArray(_vm.error))?_c('ul',_vm._l((_vm.error),function(e,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(e)+" ")])}),0):_c('span',[_vm._v(_vm._s(_vm.error))])]):_vm._e()])])])]),(_vm.selected && _vm.selected.length)?_c('div',{staticClass:"days-column"},[_c('PlanningRow',{attrs:{"data":_vm.computedPlanning.commercials,"canEdit":_vm.canEdit,"month_id":_vm.computedPlanning.month_id,"depot_zipCode":_vm.computedPlanning.depot_zipCode,"filialeAgence":{
            filiale: _vm.selectedFiliale,
            agence: _vm.selectedDeposit,
            date: _vm.selectedDate,
            month: _vm.selectedMonth,
            day: _vm.selectedDay,
            listeAMO: _vm.getListeCommercialsCPlanning,
            vt: _vm.selectedAMO
          }}})],1):_vm._e(),(
          _vm.allSelected == _vm.indeterminate &&
            !_vm.getCPlanningProcessing &&
            !_vm.getCommercialsCPlanningProcessing &&
            !_vm.notSelectedAmo
        )?_c('div',{staticClass:"info-msg"},[_vm._v(" Aucun visiteur technique sélectionné ")]):_vm._e()])]),_c('b-modal',{ref:"modal-center-warning",attrs:{"id":"modal-center-warning","no-close-on-backdrop":"","centered":"","hide-footer":true},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
        var close = ref.close;
return [_c('div',{staticClass:"info"},[_c('font-awesome-icon',{staticClass:"icon-info",attrs:{"icon":"info-circle"}}),_c('span',{staticClass:"margin-label-info"},[_vm._v("Information")])],1),_c('b-button',{staticClass:"btn-filter-close-info",attrs:{"size":"sm","variant":"outline-danger","title":"Fermer"},on:{"click":function($event){return _vm.closeModal('modal-center-warning')}}},[_c('span',{on:{"click":function($event){return close()}}},[_vm._v(" X")])])]}}])},[_c('p',[_vm._v(" De(s) visiteur technique(s) sélectionné(s) qui ne sont pas affectés à cette date"),_c('span',{staticClass:"font"},[_vm._v(" "+_vm._s(_vm.selectedDate))]),_vm._v(". ")]),(
        _vm.amoNonDisponibleList &&
          _vm.amoNonDisponibleList.name &&
          _vm.amoNonDisponibleList.name.length
      )?_c('b-tabs',{staticClass:"amo-tabs",attrs:{"lazy":""}},_vm._l((_vm.amoNonDisponibleList.name),function(item,index){return _c('b-tab',{key:'item' + index,attrs:{"title-link-class":_vm.linkClass(item)},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(item)+" ")]),_c('span',{staticClass:"icon",attrs:{"title":'Déselctionner ' + item + ' de la liste'},on:{"click":function($event){return _vm.deletedAmoLocalStorage(
                _vm.amoNonDisponibleList.id[index],
                'modal-center-warning'
              )}}},[_vm._v(" X ")])]},proxy:true}],null,true)})}),1):_vm._e()],1),_c('b-modal',{ref:"modal-center-amo",attrs:{"id":"modal-center-amo","no-close-on-backdrop":"","centered":"","hide-footer":true},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
              var close = ref.close;
return [_c('h6',[_vm._v(" De(s) visiteur technique(s) sélectionné(s) dans le filtre de recherche ")]),_c('b-button',{staticClass:"btn-filter-close-info",attrs:{"size":"sm","variant":"outline-danger","title":"Fermer"},on:{"click":function($event){return _vm.closeModal('modal-center-amo')}}},[_c('span',{on:{"click":close}},[_vm._v(" X")])])]}}])},[_c('div',{staticClass:"label-div"},[_vm._v(" De(s) visiteur technique(s) disponible(s) à cette liste ")]),(_vm.amoDisponibleInList && _vm.amoDisponibleInList.length)?_c('b-tabs',{staticClass:"amo-tabs",attrs:{"lazy":""}},_vm._l((_vm.amoDisponibleInList),function(item,index){return _c('b-tab',{key:'item' + index,attrs:{"title-link-class":_vm.linkClass(item)},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(item.full_name)+" ")])]},proxy:true}],null,true)})}),1):_c('div',{staticClass:"not-block"},[_vm._v("Aucun")]),_c('div',{staticClass:"label-div"},[_vm._v(" De(s) visiteur technique(s) non disponible(s) à cette liste ")]),(
        _vm.amoNonDisponibleList &&
          _vm.amoNonDisponibleList.name &&
          _vm.amoNonDisponibleList.name.length
      )?_c('b-tabs',{staticClass:"amo-tabs",attrs:{"lazy":""}},_vm._l((_vm.amoNonDisponibleList.name),function(item,index){return _c('b-tab',{key:'item' + index,attrs:{"title-link-class":_vm.linkClass(item)},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(item)+" ")]),_c('span',{staticClass:"icon",attrs:{"title":'Déselctionner ' + item + ' de la liste'},on:{"click":function($event){return _vm.deletedAmoLocalStorage(
                _vm.amoNonDisponibleList.id[index],
                'modal-center-amo'
              )}}},[_vm._v(" X ")])]},proxy:true}],null,true)})}),1):_c('div',{staticClass:"not-block"},[_vm._v("Aucun")])],1),_c('b-modal',{ref:"modal-center-filter",attrs:{"id":"modal-center-filter","centered":"","hide-footer":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
              var close = ref.close;
return [_c('b-button',{staticClass:"btn-filter-close",attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return close()}}},[_vm._v(" X ")])]}}])},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.hundleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"filters"},[_c('b-form-group',{staticClass:"planning-filtres",attrs:{"label":"Filiale","label-for":"filiale-input"}},[_c('b-form-select',{attrs:{"id":"filiale-input","options":_vm.getAvailableFiliales,"value-field":"id","text-field":"name","required":""},on:{"change":_vm.hundleFilialeChange},scopedSlots:_vm._u([(!_vm.getAvailableFiliales || !_vm.getAvailableFiliales.length)?{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- Aucune filiale disponible --")])]},proxy:true}:null],null,true),model:{value:(_vm.selectedFiliale),callback:function ($$v) {_vm.selectedFiliale=$$v},expression:"selectedFiliale"}})],1),_c('b-form-group',{staticClass:"planning-filtres",attrs:{"label":"Agence","label-for":"deposit-input"}},[_c('b-form-select',{attrs:{"id":"deposit-input","options":_vm.getAvailableDeposits,"value-field":"id","text-field":"name","required":""},on:{"change":_vm.hundleChangeFilterDeposite},scopedSlots:_vm._u([(!_vm.getAvailableDeposits || !_vm.getAvailableDeposits.length)?{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("-- Aucune agence disponible --")])]},proxy:true}:null],null,true),model:{value:(_vm.selectedDeposit),callback:function ($$v) {_vm.selectedDeposit=$$v},expression:"selectedDeposit"}})],1)],1),_c('div',{staticClass:"filters"},[(_vm.computedErrors)?_c('div',{staticClass:"error"},[(Array.isArray(_vm.computedErrors))?_c('ul',_vm._l((_vm.computedErrors),function(error,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),0):_c('span',[_vm._v("computedErrors")])]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }